<template>
  <div>
    <PersonalisedReportForm :formMode="formMode"></PersonalisedReportForm>
  </div>
</template>

<script>
import PersonalisedReportForm from '../Form/PersonalisedReportForm.vue';
import { FORM_MODES } from '@/core/constants';

export default {
  name: 'LimsPersonalisedReportAdd',
  components: {
    PersonalisedReportForm,
  },
  computed: {
    formMode() {
      return FORM_MODES.ADD;
    },
  },
};
</script>

<style></style>
